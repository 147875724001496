<template>
    <div id="footer">
        <div style="display: inline; float: left;"><br><i class="fab fa-vuejs" style="font-size: 24px; padding-left: 10px; color: #bdc4b6"></i></div>
        <div v-if="show" style="display: inline; float: right;">
            <a href="mailto:andrewbossie06@yahoo.com" target="_blank"><i class="fas fa-envelope footer-link"></i></a>
            <a href="https://abossie.medium.com" target="_blank"><i class="fab fa-medium footer-link"></i></a>
            <a href="https://github.com/andrewbossie" target="_blank"><i class="fab fa-github-square footer-link"></i></a>
            <a href="https://www.linkedin.com/in/andrew-bossie-527136133/" target="_blank"><i class="fab fa-linkedin footer-link"></i></a>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            show: false
        }
    },
    mounted(){
        this.show = true;
    },
    methods: {}
}
</script>

<style>

    #footer{
        width: 100%;
        position: fixed;
        left: 0;
        bottom: 0;
        text-align: right;
        background-color: #f1f8e9;
    }

    .footer-link { 
        color: #bdc4b6;
        font-size:48px;
        margin-right: 10px;
        margin-bottom: 10px;
    }

    .footer-link:hover { 
        transform: scale(1.1);
    }

</style>