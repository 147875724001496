<template>
  <div class="main">
    <div>
        <img src="../assets/nyc.png" alt="">
    </div>
    <br>
    <!-- <br> -->
    <div>
        <h1>Andrew Bossie</h1>
        <a href="javascript:void(0);" class="link" @click="showAbout()" style="display: inline-block; margin-right: 1em;">About</a>
        <div class="vertical" style="display: inline-block;"></div>
        <a href="javascript:void(0);" class="link" @click="showProjects()" style="display: inline-block; margin-left: 1em; text-alight: left;">Projects</a>
    </div>
  </div>
</template>

<script>
export default {
    data() {
        return {
            inPlace: false,
            onLeft: false,
            showLinks: false,
        }
    },
    mounted(){},
    methods: {
        showAbout(){
            // emit
            this.$emit('about', true);
            this.showLinks = false;
        },
        showProjects(){
            // emit
            this.$emit('projects', true);
            this.showLinks = false;
        }
    }
}
</script>

<style>
    .main { 
        width: 100%;
        height: 100%;
        position: absolute;
        margin-top: 80px;
    }

    img {
        width: 350px;
        z-index: 1;
    }

    .vertical {
            border-left: 1px solid black;
            height: 3em;
            position:absolute;
            left: 50%;
    }

    a { 
        color: #2c3e50;
        text-decoration: none;
        font-size: 26px;
        min-width: 100px;
    }

    h1 {
        font-size: 38px;
    }

    .link:hover { 
        color: white;
        background-color: #2c3e50;
        border-radius: 5px;
        cursor: pointer;
    }

    ul {
        margin: auto 0
    }
</style>
