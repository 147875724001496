<template>
  <div class="projects">
    <div class="projects-text">
        <div style="max-width: 500px; margin: 0 auto;">
            <ul>
                <li>
                    <h2>
                        <i>QAnon Sentiment Analysis</i><br>
                        <a href="https://abossie.medium.com/sentiment-analysis-of-qanon-drops-75b27b3437e6" target="_blank"><i class="fab fa-medium project-link"></i></a>
                        <a href="https://github.com/andrewbossie/qanon-sentiment" target="_blank"><i class="fab fa-github-square project-link"></i></a>
                    </h2>
                    <p>
                        An unsupervised approach to understanding the sentiment of QAnon drops using k-means clustering and word2vec. <br>
                    </p>
                </li>
                <br>
                <br>
                <li>
                    <h2>
                        <i>Classification of Kepler Objects using Logistic Regression</i><br>
                        <a href="https://abossie.medium.com/classifying-kepler-objects-of-interest-with-logistic-regression-c1e099632236" target="_blank"><i class="fab fa-medium project-link"></i></a>
                        <a href="https://github.com/andrewbossie/koi_classification" target="_blank"><i class="fab fa-github-square project-link"></i></a>
                    </h2>
                    <p>
                        Identifying potential exoplanets from Kepler Mission data using sklean's logistic regression model. <br>
                    </p>
                </li>
                <br>
                <br>
                <li>
                    <h2>
                        <i>QAnon Text Generation (Coming Soon)</i><br>
                        <!-- <a href="#" target="_blank"><i class="fab fa-github-quare project-link"></i></a> -->
                    </h2>
                    <p>
                        Generation of artificial QAnon drops using recurrent neural network with word2vec. <br>
                    </p>
                </li>
                <br>
                <br>
                <li>
                    <h2>
                        <i>Dell Redfish Telemetry Client</i><br>
                        <a href="https://github.com/andrewbossie/SER401-Redfish" target="_blank"><i class="fab fa-github-square project-link"></i></a>
                    </h2>
                    <p>
                        Arizona State University Capstone project in collaboration with Dell. Integration and data visualization of Dell servers using Grafana and InfluxDB. <br>
                    </p>
                </li>
                <br>
                <br>
                <li>
                    <h2>
                        <i>The Bible According to Machine Learning</i><br>
                        <a href="https://github.com/andrewbossie/BibleRNN" target="_blank"><i class="fab fa-github-square project-link"></i></a>
                    </h2>
                    <p>
                        Bible Verses generated using LSTM trained on the KJV Bible. <br>
                    </p>
                </li>
                <br>
                <br>
                <li>
                    <h2>
                        <i>Express Predict</i><br>
                        <a href="https://github.com/andrewbossie/ExpressPredict" target="_blank"><i class="fab fa-github-square project-link"></i></a>
                    </h2>
                    <p>
                        Python/Flask based web app that provides automated extraction, analysis and prediction on time-series data. <br>
                    </p>
                </li>
                <br>
                <br>
                <li>
                    <h2>
                        <i>Vid Predict (Work in Progress)</i><br>
                        <a href="https://github.com/andrewbossie" target="_blank"><i class="fab fa-github-square project-link"></i></a>
                    </h2>
                    <p>
                        Proof of concept image prediction using video frames as temporal input data. <br>
                    </p>
                </li>
            </ul>
            <br>
            <br>
            <br>
            <br>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    data() {
        return {}
    },
    mounted(){},
    methods: {}
}
</script>

<style>

    a { 
        color: #2c3e50;
        text-decoration: none;
        font-size: 26px;
        min-width: 100px;
    }

    .projects {
        margin: auto 0;
        overflow-x: auto;
        position: relative;
        text-align: center;
    }

    .project-link { 
        color: #bdc4b6;
        font-size:28px;
        margin-right: 10px;
        margin-bottom: 10px;
    }

    .project-link:hover { 
        transform: scale(1.1);
    }

    ul {
        margin: auto 0;
        overflow-x: auto;
    }

    .link:hover { 
        color: white;
        background-color: #2c3e50;
        border-radius: 5px;
        cursor: pointer;
    }

</style>