<template>
  <div id="app">
    <div id="sidebar" style="width: 200px; display: inline; height: 100%; float: left;">
      <transition name="sidebar-transition">
        <Sidebar v-if="this.showSidebar" @about=" showMain = false; showProjects = false; showAbout = true;" 
                                        @projects="showMain = false; showProjects = true; showAbout = false;" />
      </transition>
    </div>
    <div id="main" style="max-width: 800px; display: inline;">
      <transition name="main-transition">
        <Main v-if="this.showMain" @about=" showMain = false; showAbout = true; showSidebar = true;" 
                                  @projects="showProjects = true; showSidebar = true; showMain = false;" />
      </transition>
      <transition name="projects-transition">  
        <Projects v-if="this.showProjects" />
      </transition>
      <transition name="about-transition">
        <About v-if="this.showAbout"/>
      </transition> 
    </div>
    <transition name="footer-transition">
      <Footer v-if="this.showFooter" />
    </transition>
  </div>
</template>

<script>
import Footer from './components/Footer.vue'
import Main from './components/Main.vue'
import Sidebar from './components/Sidebar.vue'
import Projects from './components/Projects.vue'
import About from './components/About.vue'

export default {
  name: 'App',
  components: {
    Main,
    Footer,
    Sidebar,
    Projects,
    About
  },
  data() {
        return {
          showSidebar: false,
          showAbout: false,
          showProjects: false,
          showMain: false,
          showFooter: false
        }
  },
  mounted() {
    this.showMain = true;
    this.showFooter = true;
  }
}
</script>

<style>
#app {
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 20px;
}

body {
  padding: 0;
  margin: 0;
  background-color: #f1f8e9;
}

/* Main Landing */
.main-transition-enter {
    opacity: 0;
}

.main-transition-enter-active {
    animation: main-scroll-down 2s ease;
}

.main-transition-leave-active {
    animation: main-scroll-up 2s ease;
}

@keyframes main-scroll-down {
    0% {    
        transform: translateY(-800px);
    }
    100% {
        transform: translateY(0px);
    }
}

@keyframes main-scroll-up {
    0% {    
        transform: translateY(0px);
    }
    100% {
        transform: translateY(-800px);
    }
}

/* About */
.about-transition-enter-active, .about-transition-leave-active {
    transition: opacity 2s ease;
}

.about-transition-enter, .about-transition-leave-to {
    opacity: 0;
}

/* Projects */
.projects-transition-enter-active, .projects-transition-leave-active {
    transition: opacity 2s ease;
}

.projects-transition-enter, .projects-transition-leave-to {
    opacity: 0;
}

/* Sidebar */
.sidebar-transition-enter {
    opacity: 0;
}

.sidebar-transition-enter-active {
    animation: sidebar-scroll-right 2s ease;
}

.sidebar-transition-leave-active {
    animation: sidebar-scroll-left 2s ease;
}

@keyframes sidebar-scroll-right {
    0% {    
        transform: translateX(-200px);
    }
    100% {
        transform: translateX(0px);
    }
}

@keyframes sidebar-scroll-left {
    0% {    
        transform: translateX(0px);
    }
    100% {
        transform: translateX(-200px);
    }
}

/* Footer */
.footer-transition-enter-active, .footer-transition-leave-active {
    transition: opacity 2s;
}

.footer-transition-enter, .footer-transition-leave-to {
    opacity: 0;
}

</style>
