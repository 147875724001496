<template>
  <div class="about">
    <div class="about-text">
        <div style="max-width: 600px; margin: 0 auto;">
            <br>
            <br>
            <h2><i>Summary</i></h2>
            <p style=" text-align: justified;">I am a software engineer with 4+ years of experience currently developing data solutions for the HOA law industry. 
                I've have also worked on backend development and third party integrations for clients such as Tyson Foods and Mondelez International. 
                I have 5+ years personal and professional experience with Linux and have a passion for data science and machine learning.
            </p>
            <br>
            <h2><i>Professional Experience</i></h2>
            <ul>
                <li>ANVA | Software Engineer | June 2020 - Present</li>
                <li>Blue World Inc. | Software Engineer | May 2018 - June 2020</li>
                <li>Scottsdale Mint LLC | Shipping and Logistics | October 2014 - May 2018</li>
            </ul>
            <br>
            <h2><i>Specific Knowledge</i></h2>
            <ul>
                <li>Python | Pandas | Sci-Kit Learn | Numpy</li>
                <li>VueJS | Quasar | JQuery</li>
                <li>PHP | Laravel</li>
                <li>SQL | PostgreSQL | MongoDB</li>
                <li>Tensorflow | Keras</li>
                <li>Linux</li>
            </ul>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    data() {
        return {}
    },
    mounted(){},
    methods: {}
}
</script>

<style>

    a { 
        color: #2c3e50;
        text-decoration: none;
        font-size: 26px;
        min-width: 100px;
    }

    .about {
        margin: auto 0;
        overflow-x: auto;
    }

    .link:hover { 
        color: white;
        background-color: #2c3e50;
        border-radius: 5px;
        cursor: pointer;
    }

    ul {
        padding: 0;
        list-style-type: none;
    }

</style>