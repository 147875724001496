<template>
  <div class="sidebar">
        <div class="pic">
            <img class="sidebar-image" src="../assets/nyc.png" alt="">
        </div>
        <br>
        <div class="selection-links">
            <ul>
                <li class="sidebar-links"><a href="javascript:void(0);" class="link" @click="showAbout()">About</a></li>
                <li class="sidebar-links"><a href="javascript:void(0);" class="link" @click="showProjects()">Projects</a></li>
                <!-- <li class="sidebar-links"><a href="javascript:void(0);" class="link" @click="downloadResume()">Resum&eacute;</a></li> -->
            </ul>
        </div>
  </div>
</template>

<script>
export default {
    data() {
        return {}
    },
    props: {
        showTray: Boolean
    },
    mounted(){},
    methods: {
        showAbout(){
            // emit
            this.$emit('about', true);
        },
        showProjects(){
            // emit
            this.$emit('projects', true);
        },
        downloadResume(){
            console.log("Download")
        }
    }
}
</script>

<style>
    .sidebar { 
        width: 150px;
        height: 100%;
        position: fixed;
        float: left;
        z-index: 1000;
    }

    .sidebar-image {
        width: 100px;
        z-index: 1;
    }

    a { 
        color: #2c3e50;
        text-decoration: none;
        min-width: 100px;
    }

    ul{
        padding: 0;
        list-style-type: none;
    }

    sidebar-links {
        list-style-type: none; /* Remove bullets */
        padding: 0; /* Remove padding */
        margin: 0; /* Remove margins */   
        text-align: center;
        font-size: 26px;
    }

    .link:hover { 
        color: white;
        background-color: #2c3e50;
        border-radius: 5px;
        cursor: pointer;
    }

    .selection-links {
        text-align: center;
    }

</style>
